<!-- MOBILE -->
@if (!breakpoints['gt-sm']) {
  <div fxFlex>
    <mat-tab-group
      mat-stretch-tabs="false"
      backgroundColor="primary"
      color="accent"
      fxFlex
      class="flex-tab-group tab-tool-header"
      [(selectedIndex)]="selectedTab"
      >
      <mat-tab label="Portfolios">
        <app-portfolio-allocation
          fxLayout="column"
          fxFlex
          (selectPortfolio)="selectPortfolio($event)"
          [selectedPortfolio]="selectedPortfolio"
          (dateChanged)="setDate($event)"
          style="margin-top: 36px"
        ></app-portfolio-allocation>
      </mat-tab>
      <mat-tab label="Clients">
        <ng-template matTabContent>
          <app-client-allocation
            fxLayout="column"
            fxFlex
            [portfolio]="selectedPortfolio"
            [selectedClient]="selectedClient"
            (selectClient)="selectClient($event)"
            (portfolioRemoved)="selectPortfolio()"
            [showChip]="true"
            [asOf]="date"
            style="margin-top: 36px"
          ></app-client-allocation>
        </ng-template>
      </mat-tab>
      <mat-tab label="Consultants">
        <ng-template matTabContent>
          <app-consultant-allocation
            fxLayout="column"
            fxFlex
            [portfolio]="selectedPortfolio"
            (selectConsultant)="selectConsultant($event)"
            [selectedConsultant]="selectedConsultant"
            (portfolioRemoved)="selectPortfolio()"
            [showChip]="true"
            [asOf]="date"
            style="margin-top: 36px"
          ></app-consultant-allocation>
        </ng-template>
      </mat-tab>
      <mat-tab label="Opp: Clients">
        <ng-template matTabContent>
          <app-home-opportunity-list
            fxLayout="column"
            fxFlex
            [portfolio]="selectedPortfolio"
            (portfolioRemoved)="selectPortfolio()"
            (selectOpportunity)="selectOpportunity($event)"
            [selectedOpportunity]="selectedOpportunity"
            [showChip]="true"
            style="margin-top: 36px"
          ></app-home-opportunity-list>
        </ng-template>
      </mat-tab>
      <mat-tab label="Opp: Consultants">
        <ng-template matTabContent>
          <app-opportunity-list-consultant
            fxLayout="column"
            fxFlex
            [portfolio]="selectedPortfolio"
            (portfolioRemoved)="selectPortfolio()"
            (selectConsultant)="selectOpportunityConsultant($event)"
            [selectedConsultant]="selectedOpportunityConsultant"
            [showChip]="true"
          ></app-opportunity-list-consultant>
        </ng-template>
      </mat-tab>
      <mat-tab label="Logs">
        <ng-template matTabContent>
          <app-log-list-home
            fxLayout="column"
            fxFlex
            [portfolio]="selectedPortfolio"
            [client]="selectedClient"
            [consultant]="selectedConsultant"
            [opportunity]="selectedOpportunity"
            [opportunityConsultant]="selectedOpportunityConsultant"
            [showChip]="true"
            (portfolioRemoved)="selectPortfolio()"
            (clientRemoved)="selectClient()"
            (consultantRemoved)="selectConsultant()"
            (opportunityRemoved)="selectOpportunity()"
            (opportunityConsultantRemoved)="selectOpportunityConsultant()"
            style="margin-top: 36px"
          ></app-log-list-home>
        </ng-template>
      </mat-tab>
    </mat-tab-group>
  </div>
}

<!-- DESKTOP -->
@if (breakpoints['gt-sm']) {
  <div fxFlex fxLayout="row">
    <!-- Portfolio Allocations -->
    <div fxFlex fxLayout="column" class="mat-elevation-z4 abs-margin-vertical abs-margin-half-horizontal">
      <mat-tab-group mat-stretch-tabs="false" fxFlex fxLayout="column" animationDuration="500ms" class="flex-tab-group tab-tool-header">
        <mat-tab label="PORTFOLIOS" fxFlex fxLayout="column">
          <app-portfolio-allocation
            fxLayout="column"
            fxFlex
            (selectPortfolio)="selectPortfolio($event)"
            [selectedPortfolio]="selectedPortfolio"
            (dateChanged)="setDate($event)"
          ></app-portfolio-allocation>
        </mat-tab>
      </mat-tab-group>
    </div>
    <div fxFlex fxLayout="column" class="mat-elevation-z4 abs-margin-vertical abs-margin-half-horizontal">
      <mat-tab-group mat-stretch-tabs="false" fxFlex fxLayout="column" animationDuration="500ms" class="flex-tab-group tab-tool-header">
        <mat-tab label="CLIENTS" fxFlex fxLayout="column">
          <app-client-allocation
            fxLayout="column"
            fxFlex
            [portfolio]="selectedPortfolio"
            [selectedClient]="selectedClient"
            (selectClient)="selectClient($event)"
            [asOf]="date"
          ></app-client-allocation>
        </mat-tab>
        <mat-tab label="CONSULTANTS" fxFlex fxLayout="column">
          <ng-template matTabContent>
            <app-consultant-allocation
              fxLayout="column"
              fxFlex
              [portfolio]="selectedPortfolio"
              (selectConsultant)="selectConsultant($event)"
              [selectedConsultant]="selectedConsultant"
              [asOf]="date"
            ></app-consultant-allocation>
          </ng-template>
        </mat-tab>
        <mat-tab label="OPP: Clients" fxFlex fxLayout="column">
          <ng-template matTabContent>
            <app-home-opportunity-list
              fxLayout="column"
              fxFlex
              [portfolio]="selectedPortfolio"
              (selectOpportunity)="selectOpportunity($event)"
              [selectedOpportunity]="selectedOpportunity"
            ></app-home-opportunity-list>
          </ng-template>
        </mat-tab>
        <mat-tab label="OPP: Consultants" fxFlex fxLayout="column">
          <ng-template matTabContent>
            <app-opportunity-list-consultant
              fxLayout="column"
              fxFlex
              [portfolio]="selectedPortfolio"
              (selectConsultant)="selectOpportunityConsultant($event)"
              [selectedConsultant]="selectedOpportunityConsultant"
            ></app-opportunity-list-consultant>
          </ng-template>
        </mat-tab>
      </mat-tab-group>
    </div>
    <div fxFlex fxLayout="column" class="mat-elevation-z4 abs-margin-vertical abs-margin-half-horizontal">
      <mat-tab-group mat-stretch-tabs="false" fxFlex fxLayout="column" animationDuration="500ms" class="flex-tab-group tab-tool-header">
        <mat-tab label="LOGS" fxFlex fxLayout="column">
          <app-log-list-home
            fxLayout="column"
            [client]="selectedClient"
            [consultant]="selectedConsultant"
            [opportunity]="selectedOpportunity"
            [opportunityConsultant]="selectedOpportunityConsultant"
            fxFlex
          ></app-log-list-home>
        </mat-tab>
      </mat-tab-group>
    </div>
  </div>
}
