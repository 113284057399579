import { LayoutModule } from '@angular/cdk/layout';
import { PlatformModule } from '@angular/cdk/platform';
import { HTTP_INTERCEPTORS, provideHttpClient, withInterceptorsFromDi } from '@angular/common/http';
import { DEFAULT_CURRENCY_CODE, ErrorHandler, NgModule } from '@angular/core';
import { MatIconRegistry } from '@angular/material/icon';
import { MAT_SORT_DEFAULT_OPTIONS } from '@angular/material/sort';
import { BrowserModule } from '@angular/platform-browser';
import { BrowserAnimationsModule } from '@angular/platform-browser/animations';
import { RouterModule } from '@angular/router';
import { ServiceWorkerModule } from '@angular/service-worker';
import { MSAL_INSTANCE, MsalModule, MsalRedirectComponent, MsalService } from '@azure/msal-angular';
import { BrowserCacheLocation, IPublicClientApplication, PublicClientApplication } from '@azure/msal-browser';
import { environment } from 'environments/environment';
import { AppComponent } from './app.component';
import { BusinessModule } from './modules/common/business/business.module';
import { ServiceWorkerTrackerService } from './modules/common/business/tracker/services/service-worker-tracker.service';
import { TrackerService } from './modules/common/business/tracker/services/tracker.service';
import { GlobalErrorHandler } from './modules/common/framework/error/error-handler';
import { HttpErrorInterceptor } from './modules/common/framework/error/error.interceptor';
import { FrameworkModule } from './modules/common/framework/framework.module';
import { MaterialModule } from './modules/common/material-module';
import { AppRoutingModule } from './modules/routes/app-routing.module';
import { HomeModule } from './modules/routes/home/home.module';
import { LoginModule } from './modules/routes/login/login.module';

/**
 * Miscrosoft Azure Active Directory application configuration parameters.
 */
export function MSALInstanceFactory(): IPublicClientApplication {
  return new PublicClientApplication({
    auth: {
      clientId: environment.aadClientId,
      authority: `https://login.microsoftonline.com/${environment.aadTenantId}`,
      redirectUri: environment.aadRedirectUri,
    },
    cache: {
      cacheLocation: BrowserCacheLocation.LocalStorage,
      storeAuthStateInCookie: window.navigator.userAgent.indexOf('MSIE ') > -1 || window.navigator.userAgent.indexOf('Trident/') > -1, // set to true for IE 11
    },
  });
}

@NgModule({
  declarations: [AppComponent],
  bootstrap: [AppComponent, MsalRedirectComponent],
  imports: [
    BrowserModule,
    AppRoutingModule,
    BrowserAnimationsModule,
    FrameworkModule,
    BusinessModule,
    MaterialModule,
    LayoutModule,
    RouterModule,
    HomeModule,
    LoginModule,
    MsalModule,
    PlatformModule,
    ServiceWorkerModule.register('arms-service-worker.js', { enabled: environment.production }),
  ],
  providers: [
    { provide: DEFAULT_CURRENCY_CODE, useValue: 'USD' },
    {
      provide: MSAL_INSTANCE,
      useFactory: MSALInstanceFactory,
    },
    {
      provide: MAT_SORT_DEFAULT_OPTIONS,
      useValue: {
        disableClear: true,
      },
    },
    {
      provide: ErrorHandler,
      useClass: GlobalErrorHandler,
    },
    {
      provide: HTTP_INTERCEPTORS,
      useClass: HttpErrorInterceptor,
      multi: true,
    },
    MsalService,
    provideHttpClient(withInterceptorsFromDi()),
  ],
})
export class AppModule {
  constructor(matIconRegistry: MatIconRegistry, trackerService: TrackerService, serviceWorkerTrackerService: ServiceWorkerTrackerService) {
    matIconRegistry.setDefaultFontSetClass('mdi');

    trackerService.start(environment.gtm);
    serviceWorkerTrackerService.start();
  }
}
