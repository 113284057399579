import { HttpErrorResponse, HttpEvent, HttpHandler, HttpInterceptor, HttpRequest } from '@angular/common/http';
import { Injectable } from '@angular/core';
import { Observable, throwError } from 'rxjs';
import { catchError } from 'rxjs/operators';
import { SlackErrorRequest } from '../../business/slack/slack.model';
import { SlackService } from '../../business/slack/slack.service';
import { TrackerService } from '../../business/tracker/services/tracker.service';

/**
 * HTTP interceptor to handle errors.
 */
@Injectable()
export class HttpErrorInterceptor implements HttpInterceptor {
  constructor(private slackService: SlackService, private trackerService: TrackerService) {}

  /**
   *
   * @param request the http request object
   * @param next handler to call next interceptor
   * @returns an observable that emits when the server responds
   */
  intercept(request: HttpRequest<any>, next: HttpHandler): Observable<HttpEvent<any>> {
    return next.handle(request).pipe(
      catchError((response: HttpErrorResponse) => {
        this.sendServerError(response, request);

        return throwError(response);
      })
    );
  }

  /**
   * Sends the error to be logged in slack.
   *
   * @param response the response from the server
   * @param request the original request
   */
  private sendServerError(response: HttpErrorResponse, request: HttpRequest<any>): void {
    // ignore access denied / business / connection reset errors
    if (response.status === 400 || response.status === 403 || response.status === -1) {
      return;
    }

    // ignore slack requests (would cause an infinite loop)
    if (this.slackService.matches(request.url)) {
      return;
    }

    const data: SlackErrorRequest = {
      title: 'ARMS server error reported.',
      stackTrace: response.error?.detail,
      fields: [
        {
          title: 'Location',
          value: location.href,
        },
        {
          title: 'Error type',
          value: response.status + ' ' + response.statusText,
        },
        {
          title: 'Request',
          value: request.method + ' ' + request.urlWithParams,
        },
        {
          title: 'Recent activity',
          value: this.trackerService.history.join('\n'),
        },
      ],
    };

    this.slackService.sendError(data);
  }
}
