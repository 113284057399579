import { NgModule } from '@angular/core';
import { RouterModule, Routes } from '@angular/router';
import { environment } from 'environments/environment';
import { AccountAddOrEditComponent } from '../common/business/account/account-add-or-edit/account-add-or-edit.component';
import { AccountDetailsComponent } from '../common/business/account/account-details/account-details.component';
import { accountResolver } from '../common/business/account/services/account.service';
import { clientResolver } from '../common/business/client/services/client.service';
import { AddAccountGroupsSidenavComponent } from '../common/business/mailing/components/add-account-groups-sidenav/add-account-groups-sidenav.component';
import { SearchLogSidenavComponent } from '../common/business/search/components/search-log-sidenav/search-log-sidenav.component';
import { HomeComponent } from '../routes/home/components/home/home.component';
import { LoginWithPasswordComponent } from '../routes/login/components/login-with-password/login-with-password.component';
import { LoginComponent } from '../routes/login/components/login.component';
import { authGuard } from '../routes/login/guards/auth.guard';
import { permissionGuard } from '../routes/login/guards/permission.guard';
import { UserPermissionEnum } from '../routes/login/model/user-permission.enum';
import { FakeRouteComponent } from './fake-route/fake-route.component';

const routes: Routes = [
  {
    path: 'login',
    component: LoginComponent,
  },

  // Route for password login, not available in production
  ...(environment.allowPasswordLogin
    ? [
        {
          path: 'login-with-password',
          component: LoginWithPasswordComponent,
        },
      ]
    : []),

  {
    path: '',
    component: HomeComponent,
    canActivate: [authGuard],
    data: {
      title: 'Home',
      history: true,
    },
  },
  {
    path: 'contact',
    loadChildren: () => import('./contact/contact.module'),
  },
  {
    path: 'transactions',
    loadChildren: () => import('./transaction-table/transaction-table.module'),
  },
  {
    path: 'accounts',
    loadChildren: () => import('./account-table/account-table.module'),
  },
  {
    path: 'contacts',
    loadChildren: () => import('./contact-table/contact-table.module'),
  },
  {
    path: 'import',
    loadChildren: () => import('./import/import.module'),
  },
  {
    path: 'mailing',
    loadChildren: () => import('./mailing/mailing.module'),
  },
  {
    path: 'admin',
    loadChildren: () => import('./admin-page/admin-page.module'),
  },
  {
    path: 'user-actions',
    loadChildren: () => import('./user-actions-page/user-actions-page.module'),
  },
  {
    path: 'logs/report',
    loadChildren: () => import('./log-report/log-report.module'),
  },
  {
    path: 'opportunity/report',
    loadChildren: () => import('./opportunity-report/opportunity-report.module'),
  },
  {
    path: ':type/template',
    loadChildren: () => import('./entity-template/entity-template.module'),
  },
  {
    path: 'client',
    loadChildren: () => import('./client/client.module'),
  },
  {
    path: 'opportunities',
    loadChildren: () => import('./opportunity-table/opportunity-table.module'),
  },
  {
    path: 'cash-flow',
    loadChildren: () => import('./cash-flow/cash-flow.module'),
  },
  {
    path: 'inscricao',
    loadChildren: () => import('./unsubscribe/unsubscribe.module'),
  },
  {
    path: 'fees-flows',
    loadChildren: () => import('./fees-flows/fees-flows.module'),
  },
  {
    path: 'portfolio',
    loadChildren: () => import('./portfolio/portfolio.module'),
  },
  {
    path: 'inbox',
    loadChildren: () => import('./inbox/inbox.module'),
  },
  {
    path: 'exception',
    loadChildren: () => import('./exception-report/exception-report.module'),
  },

  // Sidenav routes

  {
    path: 'task',
    outlet: 'sidenav',
    children: [
      {
        path: '',
        loadChildren: () => import('./task/task.module'),
      },
    ],
  },
  {
    path: 'log',
    outlet: 'sidenav',
    children: [
      {
        path: '',
        loadChildren: () => import('./log/log.module'),
      },
    ],
  },
  {
    path: 'opportunity',
    outlet: 'sidenav',
    children: [
      {
        path: '',
        loadChildren: () => import('./opportunity-sidenav/opportunity-sidenav.module'),
      },
    ],
  },
  {
    path: 'doc',
    outlet: 'sidenav',
    children: [
      {
        path: '',
        loadChildren: () => import('./document-sidenav/document-sidenav.module'),
      },
    ],
  },
  {
    path: 'search/logs',
    component: SearchLogSidenavComponent,
    outlet: 'sidenav',
    canActivate: [authGuard],
  },
  {
    path: 'account/:idtAccount',
    component: AccountDetailsComponent,
    outlet: 'sidenav',
    canActivate: [authGuard],
    resolve: {
      account: accountResolver,
    },
  },
  {
    path: 'account/:id/new',
    component: AccountAddOrEditComponent,
    outlet: 'sidenav',
    canActivate: [authGuard, permissionGuard],
    resolve: {
      client: clientResolver,
    },
    data: {
      permission: UserPermissionEnum.CREATE_ACCOUNT,
    },
  },
  {
    path: 'account/:idtAccount/mailing',
    component: AddAccountGroupsSidenavComponent,
    outlet: 'sidenav',
    canActivate: [authGuard, permissionGuard],
    resolve: {
      account: accountResolver,
    },
    data: {
      permission: UserPermissionEnum.CREATE_ACCOUNT,
    },
  },
  {
    path: 'account/:id/:idtAccount/edit',
    component: AccountAddOrEditComponent,
    outlet: 'sidenav',
    canActivate: [authGuard, permissionGuard],
    resolve: {
      client: clientResolver,
      account: accountResolver,
    },
    data: {
      permission: UserPermissionEnum.EDIT_ACCOUNT,
    },
  },
  {
    path: 'transaction',
    outlet: 'sidenav',
    children: [
      {
        path: '',
        loadChildren: () => import('./sidenav/transaction-sidenav/transaction-sidenav.module'),
      },
    ],
  },
  {
    path: 'contact',
    outlet: 'sidenav',
    children: [
      {
        path: '',
        loadChildren: () => import('./sidenav/contact-sidenav/contact-sidenav.module'),
      },
    ],
  },
  {
    path: 'portfolio',
    outlet: 'sidenav',
    children: [
      {
        path: '',
        loadChildren: () => import('./sidenav/portfolio-sidenav/portfolio-sidenav.module'),
      },
    ],
  },
  {
    path: 'fake-route',
    component: FakeRouteComponent,
  },
  {
    path: '**',
    redirectTo: '',
  },
];

@NgModule({
  imports: [RouterModule.forRoot(routes, { paramsInheritanceStrategy: 'always', bindToComponentInputs: true })],
  exports: [RouterModule],
})
export class AppRoutingModule {}
