<!-- MOBILE -->
<div class="abs-container-sm tw-flex tw-flex-1">
  <mat-tab-group
    mat-stretch-tabs="false"
    backgroundColor="primary"
    color="accent"
    class="flex-tab-group tab-tool-header tw-flex tw-flex-1"
    [(selectedIndex)]="selectedTab"
  >
    <mat-tab label="Portfolios">
      <app-portfolio-allocation
        class="tw-mt-[36px] tw-flex tw-flex-1 tw-flex-col"
        (selectPortfolio)="selectPortfolio($event)"
        [selectedPortfolio]="selectedPortfolio"
        (dateChanged)="setDate($event)"
      ></app-portfolio-allocation>
    </mat-tab>
    <mat-tab label="Clients">
      <ng-template matTabContent>
        <app-client-allocation
          class="tw-mt-[36px] tw-flex tw-flex-1 tw-flex-col"
          [portfolio]="selectedPortfolio"
          [selectedClient]="selectedClient"
          (selectClient)="selectClient($event)"
          (portfolioRemoved)="selectPortfolio()"
          [showChip]="true"
          [asOf]="date"
        ></app-client-allocation>
      </ng-template>
    </mat-tab>
    <mat-tab label="Consultants">
      <ng-template matTabContent>
        <app-consultant-allocation
          class="tw-mt-[36px] tw-flex tw-flex-1 tw-flex-col"
          [portfolio]="selectedPortfolio"
          (selectConsultant)="selectConsultant($event)"
          [selectedConsultant]="selectedConsultant"
          (portfolioRemoved)="selectPortfolio()"
          [showChip]="true"
          [asOf]="date"
        ></app-consultant-allocation>
      </ng-template>
    </mat-tab>
    <mat-tab label="Opp: Clients">
      <ng-template matTabContent>
        <app-home-opportunity-list
          class="tw-mt-[36px] tw-flex tw-flex-1 tw-flex-col"
          [portfolio]="selectedPortfolio"
          (portfolioRemoved)="selectPortfolio()"
          (selectOpportunity)="selectOpportunity($event)"
          [selectedOpportunity]="selectedOpportunity"
          [showChip]="true"
        ></app-home-opportunity-list>
      </ng-template>
    </mat-tab>
    <mat-tab label="Opp: Consultants">
      <ng-template matTabContent>
        <app-opportunity-list-consultant
          class="tw-flex tw-flex-1 tw-flex-col"
          [portfolio]="selectedPortfolio"
          (portfolioRemoved)="selectPortfolio()"
          (selectConsultant)="selectOpportunityConsultant($event)"
          [selectedConsultant]="selectedOpportunityConsultant"
          [showChip]="true"
        ></app-opportunity-list-consultant>
      </ng-template>
    </mat-tab>
    <mat-tab label="Logs">
      <ng-template matTabContent>
        <app-log-list-home
          class="tw-mt-[36px] tw-flex tw-flex-1 tw-flex-col"
          [portfolio]="selectedPortfolio"
          [client]="selectedClient"
          [consultant]="selectedConsultant"
          [opportunity]="selectedOpportunity"
          [opportunityConsultant]="selectedOpportunityConsultant"
          [showChip]="true"
          (portfolioRemoved)="selectPortfolio()"
          (clientRemoved)="selectClient()"
          (consultantRemoved)="selectConsultant()"
          (opportunityRemoved)="selectOpportunity()"
          (opportunityConsultantRemoved)="selectOpportunityConsultant()"
        ></app-log-list-home>
      </ng-template>
    </mat-tab>
  </mat-tab-group>
</div>

<!-- DESKTOP -->
<div class="abs-container-gt-sm tw-flex tw-flex-1">
  <!-- Portfolio Allocations -->
  <div class="mat-elevation-z4 abs-margin-vertical abs-margin-half-horizontal tw-flex tw-flex-1 tw-flex-col">
    <mat-tab-group mat-stretch-tabs="false" animationDuration="500ms" class="flex-tab-group tab-tool-header tw-flex tw-flex-1 tw-flex-col">
      <mat-tab label="PORTFOLIOS" class="tw-flex tw-flex-1 tw-flex-col">
        <app-portfolio-allocation
          class="tw-flex tw-flex-1 tw-flex-col"
          (selectPortfolio)="selectPortfolio($event)"
          [selectedPortfolio]="selectedPortfolio"
          (dateChanged)="setDate($event)"
        ></app-portfolio-allocation>
      </mat-tab>
    </mat-tab-group>
  </div>
  <div class="mat-elevation-z4 abs-margin-vertical abs-margin-half-horizontal tw-flex tw-flex-1 tw-flex-col">
    <mat-tab-group mat-stretch-tabs="false" animationDuration="500ms" class="flex-tab-group tab-tool-header tw-flex tw-flex-1 tw-flex-col">
      <mat-tab label="CLIENTS" class="tw-flex tw-flex-1 tw-flex-col">
        <app-client-allocation
          class="tw-flex tw-flex-1 tw-flex-col"
          [portfolio]="selectedPortfolio"
          [selectedClient]="selectedClient"
          (selectClient)="selectClient($event)"
          [asOf]="date"
        ></app-client-allocation>
      </mat-tab>
      <mat-tab label="CONSULTANTS" class="tw-flex tw-flex-1 tw-flex-col">
        <ng-template matTabContent>
          <app-consultant-allocation
            class="tw-flex tw-flex-1 tw-flex-col"
            [portfolio]="selectedPortfolio"
            (selectConsultant)="selectConsultant($event)"
            [selectedConsultant]="selectedConsultant"
            [asOf]="date"
          ></app-consultant-allocation>
        </ng-template>
      </mat-tab>
      <mat-tab label="OPP: Clients" class="tw-flex tw-flex-1 tw-flex-col">
        <ng-template matTabContent>
          <app-home-opportunity-list
            class="tw-flex tw-flex-1 tw-flex-col"
            [portfolio]="selectedPortfolio"
            (selectOpportunity)="selectOpportunity($event)"
            [selectedOpportunity]="selectedOpportunity"
          ></app-home-opportunity-list>
        </ng-template>
      </mat-tab>
      <mat-tab label="OPP: Consultants" class="tw-flex tw-flex-1 tw-flex-col">
        <ng-template matTabContent>
          <app-opportunity-list-consultant
            class="tw-flex tw-flex-1 tw-flex-col"
            [portfolio]="selectedPortfolio"
            (selectConsultant)="selectOpportunityConsultant($event)"
            [selectedConsultant]="selectedOpportunityConsultant"
          ></app-opportunity-list-consultant>
        </ng-template>
      </mat-tab>
    </mat-tab-group>
  </div>
  <div class="mat-elevation-z4 abs-margin-vertical abs-margin-half-horizontal tw-flex tw-flex-1 tw-flex-col">
    <mat-tab-group mat-stretch-tabs="false" animationDuration="500ms" class="flex-tab-group tab-tool-header tw-flex tw-flex-1 tw-flex-col">
      <mat-tab label="LOGS" class="tw-flex tw-flex-1 tw-flex-col">
        <app-log-list-home
          [client]="selectedClient"
          [consultant]="selectedConsultant"
          [opportunity]="selectedOpportunity"
          [opportunityConsultant]="selectedOpportunityConsultant"
          class="tw-flex tw-flex-1 tw-flex-col"
        ></app-log-list-home>
      </mat-tab>
    </mat-tab-group>
  </div>
</div>
