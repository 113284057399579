import { Component, OnInit } from '@angular/core';
import { ActivatedRoute, Router } from '@angular/router';
import { TrackerService } from 'app/modules/common/business/tracker/services/tracker.service';
import { environment } from 'environments/environment';
import { AuthService } from '../../../common/business/auth/services/auth.service';

/**
 * Handles the login form page component. If a user already logged in,
 * automatically redirects to the target page, otherwise opens the
 * username/password form for the user tries to authenticate in the
 * server an gets an access token for the rest API
 */
@Component({
  selector: 'app-login',
  templateUrl: './login.component.html',
  styleUrls: ['./login.component.scss'],
})
export class LoginComponent implements OnInit {
  busy = false;
  loginError = false;

  constructor(
    private authService: AuthService,
    private route: ActivatedRoute,
    private router: Router,
    private trackerService: TrackerService,
  ) {}

  ngOnInit(): void {
    // If AD is not configured, redirect to login with password
    if (!environment.aadClientId) {
      this.router.navigate(['/login-with-password']);
    }

    // Verifies if the user is already logged in and redirects if so
    this.authService.isLoggedIn().subscribe((loggedIn) => {
      if (loggedIn) {
        this.redirect();
      }
    });
  }

  /**
   * Login user with Azure Active Directory.
   */
  loginAD(): void {
    this.busy = true;
    this.authService.initiliazeLoginAAD().subscribe({
      next: () => {
        this.trackerService.event('login', 'success', {
          method: 'AAD',
        });
        this.redirect();
      },
      error: (error) => {
        this.trackerService.event('login', 'error', {
          method: 'AAD',
          message: error?.error?.message || error?.message,
        });
        this.loginError = true;
        this.busy = false;
      },
    });
  }

  /**
   * Go to the target url after a successfully login
   */
  redirect(): void {
    const url = this.route.snapshot.queryParams.returnUrl || '';
    this.router.navigateByUrl(url);
  }
}
