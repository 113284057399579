import { Component, OnInit } from '@angular/core';
import { ActivatedRoute, Router } from '@angular/router';
import { TrackerService } from 'app/modules/common/business/tracker/services/tracker.service';
import { finalize } from 'rxjs/operators';
import { AuthService } from '../../../../common/business/auth/services/auth.service';

/**
 * Represents fields in the login form.
 */
interface LoginForm {
  username: string;
  password: string;
}

/**
 * Component for the page for login with user and password.
 */
@Component({
  selector: 'app-login-with-password',
  templateUrl: './login-with-password.component.html',
  styleUrls: ['./login-with-password.component.scss'],
})
export class LoginWithPasswordComponent implements OnInit {
  busy = false;

  model: LoginForm = {
    username: '',
    password: '',
  };

  loginError = false;

  constructor(private authService: AuthService, private route: ActivatedRoute, private router: Router, private trackerService: TrackerService) {}

  ngOnInit(): void {
    // subscribes to logged user and automatically redirects when user already logged in.
    this.authService.isLoggedIn().subscribe((loggedIn) => {
      if (loggedIn) {
        this.redirect();
      }
    });
  }

  /**
   * Go to the target url after a successfully login
   */
  redirect(): void {
    const url = this.route.snapshot.queryParams.returnUrl || '';
    this.router.navigateByUrl(url);
  }

  /**
   * Login user with form data.
   */
  login(): void {
    if (!this.model.username || !this.model.password) {
      return;
    }
    this.busy = true;
    this.authService
      .login(this.model.username, this.model.password)
      .pipe(finalize(() => (this.busy = false)))
      .subscribe(
        () => {
          this.trackerService.event('login', 'success', {
            method: 'form',
          });
          this.redirect();
        },
        (error) => {
          this.trackerService.event('Login', 'error', {
            method: 'form',
            message: error?.error?.message || error?.message,
          });
          this.loginError = true;
        }
      );
  }
}
