import { Component, DestroyRef } from '@angular/core';
import { ClientAllocation } from 'app/modules/common/business/client/model/client-allocation.model';
import { ConsultantAllocation } from 'app/modules/common/business/client/model/consultant-allocation.model';
import { OpportunitiesByConsultantTO, OpportunityViewTO } from 'app/modules/common/business/opportunity/model/opportunity.model';
import { PortfolioAllocation } from 'app/modules/common/business/portfolio/model/portfolio-allocation.model';
import { TrackerService } from 'app/modules/common/business/tracker/services/tracker.service';
import { ResponsiveComponent } from 'app/modules/common/framework/components/responsive/responsive.component';
import { BreakpointService } from 'app/modules/common/framework/services/breakpoint.service';
import { DateTime } from 'luxon';

/**
 * Home page component.
 */
@Component({
  selector: 'app-home',
  templateUrl: './home.component.html',
  styleUrls: ['./home.component.scss'],
})
export class HomeComponent extends ResponsiveComponent {
  /**
   * The selected portfolio.
   */
  selectedPortfolio?: PortfolioAllocation;

  /**
   * The selected client.
   */
  selectedClient?: ClientAllocation;

  /**
   * The selected consultant.
   */
  selectedConsultant?: ConsultantAllocation;

  /**
   * The selected opportunity.
   */
  selectedOpportunity?: OpportunityViewTO;

  /**
   * The selected consultant for opportunities.
   */
  selectedOpportunityConsultant?: OpportunitiesByConsultantTO;

  /**
   * The selected reference date.
   */
  date?: DateTime;

  /**
   * The index of the selected tab. Used to programatically change tabs.
   */
  selectedTab = 0;

  constructor(public breakpointService: BreakpointService, private trackerService: TrackerService, destroyRef: DestroyRef) {
    super(breakpointService, destroyRef);
  }

  /**
   * Portfolio selection event.
   * @param portfolio the selected portfolio.
   */
  selectPortfolio(portfolio?: PortfolioAllocation): void {
    this.selectedPortfolio = portfolio;
    this.selectedClient = undefined;
    this.selectedConsultant = undefined;
    this.selectedOpportunity = undefined;
    this.selectedOpportunityConsultant = undefined;

    if (this.selectedPortfolio) {
      this.selectedTab = 1;
    } else {
      this.selectedTab = 0;
    }

    this.trackFilter();
  }

  /**
   * Client selection event.
   * @param client the selected client
   */
  selectClient(client?: ClientAllocation): void {
    this.selectedClient = client;
    this.selectedConsultant = undefined;
    this.selectedOpportunity = undefined;
    this.selectedOpportunityConsultant = undefined;

    if (this.selectedClient) {
      this.selectedTab = 5;
    } else {
      this.selectedTab = 1;
    }

    this.trackFilter();
  }

  /**
   * Consultant selection event.
   *
   * @param consultant the selected consultant
   */
  selectConsultant(consultant?: ConsultantAllocation): void {
    this.selectedConsultant = consultant;
    this.selectedClient = undefined;
    this.selectedOpportunity = undefined;
    this.selectedOpportunityConsultant = undefined;

    if (this.selectedConsultant) {
      this.selectedTab = 5;
    } else {
      this.selectedTab = 2;
    }

    this.trackFilter();
  }

  /**
   * Handle selecting an opportunity.
   *
   * @param opportunity the selected opportunity
   */
  selectOpportunity(opportunity?: OpportunityViewTO): void {
    this.selectedOpportunity = opportunity;
    this.selectedClient = undefined;
    this.selectedConsultant = undefined;
    this.selectedOpportunityConsultant = undefined;

    if (this.selectedOpportunity) {
      this.selectedTab = 5;
    } else {
      this.selectedTab = 3;
    }

    this.trackFilter();
  }

  /**
   * Handle selecting a consultant for an opportunity .
   *
   * @param opportunity the selected opportunity
   */
  selectOpportunityConsultant(opportunity?: OpportunitiesByConsultantTO): void {
    this.selectedOpportunity = undefined;
    this.selectedClient = undefined;
    this.selectedConsultant = undefined;
    this.selectedOpportunityConsultant = opportunity;

    if (this.selectedOpportunityConsultant) {
      this.selectedTab = 5;
    } else {
      this.selectedTab = 4;
    }

    this.trackFilter();
  }

  /**
   * Track changes in the selected filters.
   */
  private trackFilter(): void {
    this.trackerService.event('home', 'filter', {
      portfolio: this.selectedPortfolio?.ticker,
      client: this.selectedClient?.name,
      consultant: this.selectedConsultant?.name,
      opportunity: this.selectedOpportunity?.idtOpportunity,
      opportunityConsultant: this.selectedOpportunityConsultant?.name,
    });
  }

  /**
   * Handle changes to the selected date.
   *
   * @param date the selected date
   */
  setDate(date: DateTime): void {
    setTimeout(() => {
      this.date = date;
    });
  }
}
